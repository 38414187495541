<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" size="small" ref="ruleForm" label-width="120px" label-position="right">
      <el-form-item label="外挂名称" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>

      <el-form-item label="行为" prop="action">
        <el-select v-model="ruleForm.action" placeholder="请选择行为">
          <el-option v-for="item in actions" :key="item.type" :label="item.remarks" :value="item.type"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="描述" prop="remarks">
        <el-input v-model="ruleForm.remarks" type="textarea" placeholder="请输入描述"></el-input>
      </el-form-item>

      <el-form-item label="是否启用" prop="status">
        <el-radio-group v-model="ruleForm.status">
          <el-radio :label="0">启用</el-radio>
          <el-radio :label="1">停用</el-radio>
        </el-radio-group>
      </el-form-item>

        <el-form-item>
            <template #label><span style="height: 49px;line-height: 49px">执行条件</span></template>
            <el-divider content-position="center">
                <el-tag effect="dark">条件</el-tag>
            </el-divider>
        </el-form-item>


      <!--条件-->

      <!--      <el-form-item label="唯一归属">-->
      <!--        <el-select v-model="possessIds" multiple placeholder="请选择唯一归属">-->
      <!--          <el-option :label="item.name" :value="item.id" v-for="item in possessList"></el-option>-->
      <!--        </el-select>-->
      <!--        &lt;!&ndash;        <el-divider direction="vertical"></el-divider>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <el-radio-group v-model="ruleForm.params6">&ndash;&gt;-->
      <!--        &lt;!&ndash;          <el-radio label="1">全部</el-radio>&ndash;&gt;-->
      <!--        &lt;!&ndash;          <el-radio label="2">归属1</el-radio>&ndash;&gt;-->
      <!--        &lt;!&ndash;          <el-radio label="3">归属2</el-radio>&ndash;&gt;-->
      <!--        &lt;!&ndash;        </el-radio-group>&ndash;&gt;-->
      <!--      </el-form-item>-->


      <el-form-item v-for="(item, index) in conditionListArr" :key="item.id" :label="item.label"
                    style="text-align: center">
        <div class="my-form-item">
          <!--        创建时间模板-->
          <template v-if="item.type === 'TIME'">
            <el-select v-model="conditionData[item.id]['compare']" placeholder="请选择比较条件">
              <el-option label="大于" value="GT"></el-option>
              <el-option label="小于" value="LT"></el-option>
            </el-select>
            <el-divider direction="vertical"></el-divider>
            <el-input v-model="conditionData[item.id]['value']" placeholder="请输入多少天前的天数"
                      style="width: 100%"></el-input>
            <div style="margin-left: 10px">天</div>
          </template>
          <!--        支付金额模板-->
          <template v-if="item.type === 'MONEY'">
            <el-select v-model="conditionData[item.id]['compare']" placeholder="请选择比较条件">
              <el-option label="大于" value="GT"></el-option>
              <el-option label="大于等于" value="GE"></el-option>
              <el-option label="等于" value="EQ"></el-option>
              <el-option label="小于" value="LT"></el-option>
              <el-option label="小于等于" value="LE"></el-option>
            </el-select>
            <el-divider direction="vertical"></el-divider>
            <el-input v-model="conditionData[item.id]['value']" placeholder="请输入金额"></el-input>
          </template>


          <!--    部门   -->
          <template v-if="item.type === 'DEPT'">
            <el-select style="width: 100%" v-model="conditionData[item.id]['value']" multiple>
              <el-option v-for="dept in deptList" :key="dept.id" :value="dept.id" :label="dept.deptName"></el-option>
            </el-select>
          </template>

          <!--    唯一标识   -->
          <template v-if="item.type === 'POSSESS'">
            <el-select style="width: 100%" v-model="conditionData[item.id]['value']" multiple>
              <el-option v-for="poss in possessList" :key="poss.id" :value="poss.id" :label="poss.name"></el-option>
            </el-select>
          </template>

          <!--    机会类型   -->
          <template v-if="item.type === 'OPPORTYPE'">
            <el-select style="width: 100%" v-model="conditionData[item.id]['value']" multiple>
              <el-option :value="1" label="首咨"></el-option>
              <el-option :value="2" label="个人"></el-option>
              <el-option :value="3" label="常规"></el-option>
              <el-option :value="4" label="库存"></el-option>
              <el-option :value="5" label="唤醒"></el-option>
            </el-select>
          </template>

          <!--    成单状态   -->
          <template v-if="item.type === 'ORDERSTATUS'">
            <el-select style="width: 100%" v-model="conditionData[item.id]['value']" multiple>
              <el-option :value="0" label="未成交"></el-option>
              <el-option :value="1" label="已成交"></el-option>
              <el-option :value="2" label="未付款"></el-option>
            </el-select>
          </template>

            <template v-if="item.type === 'POND'">
                <el-select v-model="conditionData[item.id]['value']" width="100%" multiple filterable>
                    <el-option
                        v-for="pond in pondList"
                        :key="pond.id"
                        :label="pond.name"
                        :value="pond.id">
                    </el-option>
                </el-select>
            </template>

            <template v-if="item.type === 'SUBJECT'">
                <el-select v-model="conditionData[item.id]['value']" width="100%" multiple filterable>
                    <el-option
                        v-for="subject in subjectList"
                        :key="subject.id"
                        :label="subject.subjectName"
                        :value="subject.id">
                    </el-option>
                </el-select>
            </template>

            <template v-if="item.type === 'EXTRA'">
                <el-select v-model="conditionData[item.id]['value']" width="100%" multiple filterable>
                    <el-option
                            v-for="subject in extraMap[item.name]"
                            :key="subject.id"
                            :label="subject.dictLabel"
                            :value="subject.id">
                    </el-option>
                </el-select>
            </template>

          <el-divider direction="vertical"></el-divider>

          <el-button type="text" @click="delFormItem('condition', index)">删除</el-button>
        </div>
      </el-form-item>

      <el-form-item>
        <el-row>
          <el-col :span="12">
            <el-select v-model="condition" placeholder="请选择待添加的类型">
              <el-option :label="item.label" :value="item.name" v-for="item in paramsListArr"></el-option>
            </el-select>
            <el-divider direction="vertical"></el-divider>
            <el-button type="success" @click="addCondition">添加</el-button>
          </el-col>
        </el-row>
      </el-form-item>

        <el-form-item>
            <template #label><span style="height: 49px;line-height: 49px">修正内容</span></template>
            <el-divider content-position="center">
                <el-tag effect="dark">执行</el-tag>
            </el-divider>
        </el-form-item>


      <!--执行-->

      <!--      <el-form-item label="唯一归属">-->
      <!--        <el-select v-model="ruleForm.params10" placeholder="请选择唯一归属">-->
      <!--          <el-option label="1" value="1"></el-option>-->
      <!--          <el-option label="2" value="2"></el-option>-->
      <!--        </el-select>-->
      <!--        <el-divider direction="vertical"></el-divider>-->
      <!--&lt;!&ndash;        <el-radio-group v-model="ruleForm.params11">&ndash;&gt;-->
      <!--&lt;!&ndash;          <el-radio label="1">全部</el-radio>&ndash;&gt;-->
      <!--&lt;!&ndash;          <el-radio label="2">归属1</el-radio>&ndash;&gt;-->
      <!--&lt;!&ndash;          <el-radio label="3">归属2</el-radio>&ndash;&gt;-->
      <!--&lt;!&ndash;        </el-radio-group>&ndash;&gt;-->
      <!--      </el-form-item>-->

      <el-form-item v-for="(item, index) in actionListArr" :key="`action${index}`" style="text-align: center"
                    :label="item.label">
        <el-row>
          <!--        <el-select v-model="actionData[index][0]" placeholder="请选择行为">-->
          <!--          <el-option label="修正为" value="1"></el-option>-->
          <!--        </el-select>-->
          <el-col :span="4" style="color: #dd6161">
            修正为:
            <el-divider direction="vertical"></el-divider>
          </el-col>
          <el-col :span="8">
            <!--     学习顾问     -->
            <template v-if="item.type === 'kcuserId'">
              <el-select style="width: 100%" v-model="actionData[index]">
                <el-option :value="adviser.id" :label="adviser.nickName" v-for="adviser in adviserList"
                           :key="adviser.id"></el-option>
              </el-select>
            </template>

            <!--     唯一标识     -->
            <template v-else-if="item.type === 'possessId'">
              <el-select style="width: 100%" v-model="actionData[index]">
                <el-option :value="poss.id" :label="poss.name" v-for="poss in possessList"
                           :key="poss.id"></el-option>
              </el-select>
            </template>

            <!--     机会类型     -->
            <template v-else-if="item.type === 'type'">
              <el-select style="width: 100%" v-model="actionData[index]">
                <el-option :value="1" label="首咨"></el-option>
                <el-option :value="2" label="个人"></el-option>
                <el-option :value="3" label="常规"></el-option>
                <el-option :value="4" label="库存"></el-option>
                <el-option :value="5" label="唤醒"></el-option>
              </el-select>
            </template>

            <!--     机会意向     -->
            <template v-else-if="item.type === 'intention'">
              <el-select style="width: 100%" v-model="actionData[index]">
                <el-option value="高意向" label="高意向"></el-option>
                <el-option value="意向" label="意向"></el-option>
                <el-option value="跟踪" label="跟踪"></el-option>
                <el-option value="无意向" label="无意向"></el-option>
              </el-select>
            </template>

            <!--     话术节点     -->
            <template v-else-if="item.type === 'talkingSkills'">
              <el-select style="width: 100%" v-model="actionData[index]">
                <el-option value="开场白" label="开场白"></el-option>
                <el-option value="报考条件" label="报考条件"></el-option>
                <el-option value="证书介绍" label="证书介绍"></el-option>
                <el-option value="考试介绍" label="考试介绍"></el-option>
                <el-option value="兼职就业" label="兼职就业"></el-option>
                <el-option value="介绍班级" label="介绍班级"></el-option>
                <el-option value="介绍价格" label="介绍价格"></el-option>
                <el-option value="截杀" label="截杀"></el-option>
              </el-select>
            </template>

              <template v-else-if="item.type === 'pondId'">
                  <el-select style="width: 100%" v-model="actionData[index]" filterable>
                      <el-option
                          v-for="pond in pondList"
                          :key="pond.id"
                          :label="pond.name"
                          :value="pond.id">
                      </el-option>
                  </el-select>
              </template>

              <template v-else-if="item.type === 'subjectIds'">
                  <el-select style="width: 100%" v-model="actionData[index]" filterable>
                      <el-option
                              v-for="subject in subjectList"
                              :key="subject.id"
                              :label="subject.subjectName"
                              :value="subject.id">
                      </el-option>
                  </el-select>
              </template>
              <template v-else-if="extraMap[item.type]">
                  <el-select style="width: 100%" v-model="actionData[index]" filterable>
                      <el-option
                              v-for="subject in extraMap[item.type]"
                              :key="subject.id"
                              :label="subject.dictLabel"
                              :value="subject.id">
                      </el-option>
                  </el-select>
              </template>

            <!--     其他:比如备注等     -->
            <template v-else>
              <el-input v-model="actionData[index]" placeholder="请输入内容"></el-input>
            </template>

          </el-col>
          <el-col :span="5">
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="delFormItem('action', index)">删除</el-button>
          </el-col>

        </el-row>
      </el-form-item>

      <el-form-item>
        <el-row>
          <el-col :span="12">
            <el-select v-model="action" placeholder="请选择待添加的类型">
              <el-option :value="result.name" :label="result.label" v-for="result in resultListArr"></el-option>
            </el-select>
            <el-divider direction="vertical"></el-divider>
            <el-button type="danger" @click="addAction">添加</el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import * as rinseApi from '@/api/crm/rinseScript'
import * as possessApi from '@/api/crm/possess'
import * as deptApi from '@/api/system/dept'
import * as api from '@/utils/api'
import {save} from "@/api/crm/rinseScript";
import * as accountApi from "@/api/system/account";
import * as clueApi from "@/api/clue/cluePond";
import * as dictApi from "@/api/system/dictionary";


function randomString(e) {
  e = e || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
      a = t.length,
      n = "";
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n
}

export default {
  name: "RinseScript",
  data() {
    return {
        extraMap:{
            "BHBIIGGHFE":[],
            "BHBIGCFBGB":[],
            "BHBIGCFCIH":[],
        },
      // 条件绑定的选项值
        subjectList:[],
        pondList:[],
      condition: '',
      paramsListArr: [
        {label: "线索池", type: "POND", name: "pondId"},
        {label: "成交金额", type: "MONEY", name: "dealAmount"},
        {label: "创建时间", type: "TIME", name: "createTime"},
        {label: "部门", type: "DEPT", name: "deptIds"},
        {label: "唯一配置", type: "POSSESS", name: "possessId"},
        {label: "机会类型", type: "OPPORTYPE", name: "type"},
        {label: "分配时间", type: "TIME", name: "vallotTime"},
        {label: "回库时间", type: "TIME", name: "backStoreTime"},
        {label: "成交状态", type: "ORDERSTATUS", name: "orderStatus"},
        {label: "分配/领取时间", type: "TIME", name: "haveTime"},
        {label: "咨询科目", type: "SUBJECT", name: "subjectIds"},
      ],
      resultListArr: [
        {label: "创建至线索池", type: "SELF", name: "pondId"},
        {label: "咨询科目", type: "SELF", name: "subjectIds"},
        {label: "学习顾问", type: "SELF", name: "kcuserId"},
        {label: "唯一配置", type: "SELF", name: "possessId"},
        {label: "学历", type: "SELF", name: "verifyEducation"},
        {label: "机会类型", type: "SELF", name: "type"},
        {label: "项目名称", type: "SELF", name: "subjectName"},
        {label: "机会意向", type: "SELF", name: "intention"},
        {label: "话术节点", type: "SELF", name: "talkingSkills"},
        {label: "备注", type: "SELF", name: "remark"},
      ],
      conditionListArr: [],
      conditionData: {},
      //
      action: '',
      actionListArr: [],
      actionData: [],

      conditionNameObj: {},
      actionNameObj: {},
      ruleForm: {},
      rules: {
        name: [
          {required: true, message: '请输入外挂名称', trigger: 'blur'}
        ],
        action: [
          {required: true, message: '请选择行为', trigger: 'change'}
        ],
        remarks: [
          {required: true, message: '请输入描述', trigger: 'blur'}
        ],
        status: [
          {required: true, message: '请选择是否启用', trigger: 'blur'}
        ]
      },
      actions: [],
      possessList: [],
      possessIds: [],
      deptList: [],
      adviserList: [],

    };
  },
  mounted() {
    this.ruleForm = {
      name: null,
      action: null,
      remarks: null,
      status: 0,
    }
    if(this.zebj()){
        this.findExtraMap()
        this.paramsListArr.push({label: "所属团队", type: "EXTRA", name: "BHBIGCFCIH"})
        this.paramsListArr.push({label: "升级机会来源-分类", type: "EXTRA", name: "BHBIIGGHFE"})
        this.paramsListArr.push({label: "特殊分类", type: "EXTRA", name: "BHBIGCFBGB"})
        this.resultListArr.push({label: "所属团队", type: "EXTRA", name: "BHBIGCFCIH"})
        this.resultListArr.push({label: "升级机会来源-分类", type: "EXTRA", name: "BHBIIGGHFE"})
        this.resultListArr.push({label: "特殊分类", type: "EXTRA", name: "BHBIGCFBGB"})
    }
    this.findSubjectList()
    this.findPondList()
    this.findAdviser()
    this.findActions()
    this.findPossess()
    this.findDept()
    this.setData()
  },
  props: {
    rinseId: {
      type: Number,
      require: false,
      default: 0
    }
  },
  methods: {
      zebj() {
          let dataKey = document.cookie.replace(/(?:(?:^|.*;\s*)dataKey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
          return dataKey === 'zhengerbajing';
      },
      /**
       * 组装额外参数字典
       */
      findExtraMap(){
          dictApi.findExtraDictMap().then(res=>{
              if(res.success){
                  this.extraMap = res.data
              }
          })
      },
      /**
       * 获取专业列表
       */
      findSubjectList: function () {
          api.subjectList().then(res => {
              if (res.success) {
                  this.subjectList = res.data
              }
          })
      },
      findPondList() {
          clueApi.listCluePond({}).then(res => {
              if (res.success) {
                  this.pondList = res.data
              }
            this.pondList.unshift({id:1,name:'基础池'})
          })
      },
    findAdviser() {
      accountApi.adviserList().then(res => {
        if (res.success) {
          this.adviserList = res.data
        }
      })
    },
    findDept() {
      deptApi.listDept().then(res => {
        this.deptList = res.data
        // 添加默认的无部门选项到首位
        this.deptList.splice(0,0,{id:0, deptName: '无归属部门'})
      })
    },
    findPossess() {
      possessApi.listAll().then(res => {
        this.possessList = res.data
      })
    },
    findActions() {
      rinseApi.actions().then(res => {
        this.actions = res
      })
    },
    delFormItem(type, index) {
      if (type === 'condition') {
        this.conditionListArr.splice(index, 1)
        delete this.conditionData[index]
      } else {
        this.actionListArr.splice(index, 1)
        this.actionData.splice(index, 1)
      }
    },
    setData() {
      this.paramsListArr.map(item => {
        this.conditionNameObj[item.name] = item
      })
      this.resultListArr.map(item => {
        this.actionNameObj[item.name] = item
      })
      if (this.rinseId) {
        rinseApi.info(this.rinseId).then(res => {
          this.ruleForm = res.data

          const _params = JSON.parse(this.ruleForm.params || '[]')

          _params.map(item => {

            const _id = randomString(8)

            this.conditionListArr.push({
              type: item.type,
              name: item.name,
              label: this.conditionNameObj[item.name]?.label,
              id: _id
            })

            this.$set(this.conditionData, _id, {
              value: item.value,
              compare: item.compare
            })

          })

          const _content = JSON.parse(this.ruleForm.content || '[]')

          _content.map(item => {
            this.actionListArr.push({
              type: item.type,
              source:this.actionNameObj[item.type].type,
              label: this.actionNameObj[item.type].label
            })

            this.actionData.push(item.value)
          })
        })
      }

    },
    addCondition() {
      if (this.condition) {
        const _id = randomString(8)
        this.$set(this.conditionData, _id, {})
        console.log(this.condition, this.conditionNameObj, this.conditionData)
        this.conditionListArr.push({
          id: _id,
          type: this.conditionNameObj[this.condition].type,
          name: this.condition,
          label: this.conditionNameObj[this.condition].label
        })
      }
    },
    addAction() {
      if (this.action) {
        this.actionData.push()
        this.actionListArr.push({
          type: this.action,
          source:this.actionNameObj[this.action].type,
          label: this.actionNameObj[this.action].label,
          id: randomString(8)
        })
      }

    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _res = {
            ...this.ruleForm
          }

          // 执行
          const _content = []
          // 条件
          const _params = []

          // 传递动态表单数据
          this.conditionListArr.map((item, index) => {
            _params.push({
              type: item.type,
              name: item.name,
              compare: this.conditionData[item.id].compare,
              value: this.conditionData[item.id].value
            })
          })

          console.log('actionListArr',this.actionListArr)
          this.actionListArr.map((item, index) => {
            _content.push({
              type: item.type,
              source:item.source,
              value: this.actionData[index]
            })
          })

          _res.params = JSON.stringify(_params)
          _res.content = JSON.stringify(_content)

          save(_res).then(res => {
            if (res.success) {
              this.$emit('submitSuccess')
              this.$message.success(res.msg)
              this.$emit("findList")
            }
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-divider__text {
  background-color: transparent;
}

.my-form-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
