<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" @submit.native.prevent>
      <el-form-item label="名称" prop="name">
        <el-input
            v-model="queryParams.name"
            placeholder="请输入名称"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
            @submit.native.prevent="false"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            v-permission="'crm:rinseScript:add'"
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
        >新增
        </el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="rinseList">
      <el-table-column label="主键" align="center" prop="id"/>
      <el-table-column label="外挂名称" align="center" prop="name"/>
      <el-table-column label="行为" align="center" prop="action">
        <template v-slot="scope">
          <span v-for="item in actions" :key="item.type" v-if="item.type === scope.row.action">{{ item.remarks }}</span>
        </template>
      </el-table-column>
      <el-table-column label="外挂描述" align="center" prop="remarks"/>
      <el-table-column label="是否生效" align="center" prop="status">
        <template v-slot="scope">
          <span v-if="scope.row.status === 0">生效</span>
          <span v-else>不生效</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              v-permission="'crm:rinseScript:update'"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
          >修改
          </el-button>
          <el-button
              v-permission="'crm:rinseScript:delete'"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleDelete(scope.row)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.limit"
        @pagination="getList"
    />

    <el-dialog title="编辑脚本" top="20" :visible.sync="open" width="80%" append-to-body :close-on-click-modal="false">
      <RinseScript :rinse-id="rinseId" v-if="open" @submitSuccess="open = false" @findList="getList"></RinseScript>
    </el-dialog>
  </div>
</template>

<script>
import * as rinseApi from "@/api/crm/rinseScript";
import * as possApi from '@/api/crm/possess'
import RinseScript from '@/views/crm/rinse/RinseScript.vue'

export default {
  name: "Rinse",
  components: {RinseScript},
  data() {
    var validateNumber = (rule, value, callback) => {
      if (value == null || value.length > 4) {
        callback(new Error('最大允许输入9999'))
      }
      if (!(/^(\d+)$/).test(value)) {
        callback(new Error('请输入数字值'))
      } else {
        callback()
      }
    };
    return {
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 参数配置表格数据
      rinseList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 10,
        name: null
      },
      // 表单参数
      form: {},
      rules: {
        firstNumber: [{validator: validateNumber, trigger: 'blur'}],
        firstTime: [{validator: validateNumber, trigger: 'blur'}],
        opporNumber: [{validator: validateNumber, trigger: 'blur'}],
      },
      actions: [],
      rinseId: null
    };
  },
  created() {
    this.getList();
    this.findActions();
  },
  methods: {
    findActions() {
      rinseApi.actions().then(res => {
        this.actions = res
      })
    },
    /** 查询参数配置列表 */
    getList() {
      this.loading = true;
      rinseApi.listPage(this.queryParams).then(response => {
        this.rinseList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });

    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.receiveSync = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.receiveForm = {}
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.rinseId = null
      this.title = "添加配置";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.rinseId = row.id;
      this.open = true
    },
    handleDelete(row) {
      this.$confirm('是否确定删除《' + row.name + '》', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        rinseApi.deleteRinse(row.id).then(res => {
          if (res.success) {
            this.getList()
            this.$message.success("删除成功")
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    /** 提交按钮 */
    submitForm() {
      console.log()
      this.$refs["form"].validate(valid => {
        if (valid) {
          const formDept = this.$refs.deptForm.getCheckedKeys(true);
          this.form.deptIds = formDept;
          if (this.form.id != null) {
            possApi.updatePossess(this.form).then(response => {
              this.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            possApi.addPossess(this.form).then(response => {
              this.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
  }
};
</script>
