import request from '@/utils/request.js';

const url = "/manage/crm/rinse-script";

//获取分页数据
export function listPage(query) {
    return request({
        url: `${url}/listPage`, method: 'get', params: query
    })
}

//获取列表数据
export function list(query) {
    return request({
        url: `${url}/list`, method: 'get', params: query
    })
}

//获取列表数据
export function actions() {
    return request({
        url: `${url}/actions`, method: 'get'
    })
}

//获取日志分页数据
export function listLogPage(query) {
    return request({
        url: `${url}/log/listPage`, method: 'get', params: query
    })
}

/**
 * 查询详情
 * @param rinseId
 */
export function info(rinseId) {
    return request.get(`${url}/info/${rinseId}`)
}

/**
 * 保存清洗脚本内容
 * @param params
 */
export function save(params) {
    return request.post(`${url}/save`, JSON.stringify(params))
}

/**
 * 删除配置
 * @param id
 */
export function deleteRinse(id) {
    return request.delete(`${url}/delete/${id}`)
}
